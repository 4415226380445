var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"glossary-card",attrs:{"bodyClass":[
    'd-flex',
    'flex-column',
    _vm.popup ? 'px-2' : 'px-3',
    _vm.popup ? 'pt-2' : 'pt-3',
    _vm.popup ? 'pb-0' : 'pb-3' ]}},[_c('b-card-title',{class:[
      'glossary-card-title',
      'bg-paddle-' + _vm.category.toLowerCase(),
      _vm.popup ? 'p-3' : 'p-2 px-3',
      'm-0',
      'pb-0',
      'clear-fix',
      'text-white' ]},[_c('span',{staticClass:"glossary-card-title-category"},[_vm._v(_vm._s(_vm.category)+": ")]),_c('span',{staticClass:"glossary-card-title-genre"},[_vm._v(_vm._s(_vm.genre))]),_c('span',{staticClass:"glossary-card-title-icon-wrapper"},[_c('Icon',{staticClass:"glossary-card-title-icon",attrs:{"icon":(_vm.category + ":" + _vm.genre)}})],1)]),(!_vm.popup)?_c('b-aspect',{staticClass:"glossary-card-picture my-2",style:(_vm.imageStyle),attrs:{"aspect":"4:3"}}):_vm._e(),_c('b-card-text',{staticClass:"glossary-card-body flex-grow-1 bg-paddle-blue-light text-white p-3"},[_c('div',{staticClass:"mb-3"},[_c('span',{class:[
          'bg-paddle-' + _vm.category.toLowerCase(),
          'py-1',
          'px-2',
          'rounded' ]},[_c('span',{staticClass:"glossary-card-body-category"},[_vm._v(_vm._s(_vm.category)+": ")]),_c('span',{staticClass:"glossary-card-body-genre"},[_vm._v(_vm._s(_vm.genre))])]),_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('div',[_c('span',{staticClass:"glossary-card-body-youknow"},[_vm._v(_vm._s(_vm.knowPreamble[0])+" "),_c('span',{class:[
            'bg-paddle-' + _vm.category.toLowerCase(),
            'py-1',
            'px-2',
            'rounded' ]},[_vm._v(_vm._s(_vm.genre))]),_vm._v(" "+_vm._s(_vm.knowPreamble[1])+" ")]),_c('span',[_vm._v(_vm._s(_vm.youKnow))])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }